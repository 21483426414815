
import {
  apiShopLists,
  apiShopSwitchStatus,
  apiShopDelete,
  apiChangeRemark,
  apiSetUserWallet,
} from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import LsUserChange from "@/components/ls-user-change.vue";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    LsUserChange,
  },
})
export default class ShopList extends Vue {
  /** S Data **/

  apiShopLists = apiShopLists;

  pager: RequestPaging = new RequestPaging();

  searchObj: Object = {
    status: "",
    shop_info: "",
    create_time: "",
  };
  balanceForm: any = {
    action: 1,
    num: 0,
    remark: "",
    type: 1,
    id: 0,
    value: 0,
  };
  balanceShow = false;
  // 修改后的值
  get lastValue(): number {
    let total = this.balanceForm.value;
    if (this.balanceForm.action == 1) {
      total = Number(this.balanceForm.value) + this.balanceForm.num * 1;
    } else {
      total = Number(this.balanceForm.value) - this.balanceForm.num * 1;
    }
    return total;
  }
  /** E Data **/

  /** S Methods **/

  // 获取商城列表
  getShopLists(): void {
    this.pager.request({
      callback: apiShopLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 修改商城状态
  async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
    await apiShopSwitchStatus({ id });
    this.getShopLists();
  }

  async changeRemarks(text: string, id: number): Promise<void> {
    await apiChangeRemark({ id: id, remark: text });
    this.getShopLists();
  }

  // 删除商城
  async onShopDelete(row: any): Promise<void> {
    await apiShopDelete({ id: row.id });
    this.getShopLists();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getShopLists();
  }

  // 去编辑商城
  toShopEdit(id: number | any): void {
    this.$router.push({
      path: "/shop/lists_detail",
      query: {
        id: id,
      },
    });
  }

  // 修改超级管理员
  toSuperAdminUpdate(id: number | any): void {
    this.$router.push({
      path: "/shop/modify_account",
      query: {
        id: id,
      },
    });
  }

  // 模型管理
  toModelManage(id: number | any): void {
    this.$router.push({
      path: "/shop/model",
      query: {
        id: id,
      },
    });
  }

  // 去商家后台
  toShop(url: any, status: number) {
    if (status == 0) return this.$message.error("该商城暂未营业中");

    if (url.indexOf("https://") === 0) window.open(url);
    else if (url.indexOf("http://") === 0) window.open(url);
    else window.open("http://" + url);
  }

  updateStoreWallet() {
    apiSetUserWallet(this.balanceForm).then((res) => {
      this.balanceShow = false;
      this.getShopLists();
    });
  }
  /** E Methods **/

  /** S ods **/
  created() {
    this.getShopLists();
  }
}
